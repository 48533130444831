import React, { useState } from "react";
import { Link } from 'gatsby'
import { useForm } from 'react-hook-form';

const MyForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    errors,
    reset,
    formState: { isSubmitting }
  } = useForm();

  const submitForm = async formData => {
    try {
      await fetch('https://formspree.io/xnqbnebo', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(formData),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      });
      setSubmitted(true);
      reset();
    } catch (error) {
      setError(
        'submit',
        "submitError",
        `Oops! There seems to be an issue! ${error.message}`
      )
    }
  }


  const showThankYou = (
    <div className="form__confirm">
      <p>Super! Je bericht is verstuurd. Er word zo snel mogelijk contact opgenomen</p>
    </div>
  );

  const showSubmitError = msg => <p className="form__error">{msg}</p>;

  const showForm = (
    <form
      onSubmit={handleSubmit(submitForm)}
      method="POST"
      className="form"
    >
      <div className="col-sm-12 col-lg-6">
        <label
          htmlFor="voornaam"
          className="form__label">
          Voornaam*
        </label>
        <input
          type="text"
          name="voornaam"
          className="form__input"
          ref={register({
            required: 'Voornaam is vereist'
          })}
          disabled={isSubmitting}
        />
        {errors.voornaam && <div className="form__error">{errors.voornaam.message}</div>}
      </div>

      <div className="col-sm-12 col-lg-6">
        <label
          name="achternaam"
          className="form__label">
          Achternaam*
        </label>
        <input
          type="text"
          name="achternaam"
          className="form__input"
          ref={register({
            required: 'Achternaam is vereist'
          })}
          disabled={isSubmitting}
        />
        {errors.achternaam && <div className="form__error">{errors.achternaam.message}</div>}
      </div>

      <div className="col-sm-12 col-lg-6">
        <label
          name="email"
          className="form__label">
          Email*
        </label>
        <input
          type="email"
          name="email"
          className="form__input"
          ref={register({
            required: 'Email is vereist'
          })}
          disabled={isSubmitting}
        />
        {errors.email && <div className="form__error">{errors.email.message}</div>}
      </div>

      <div className="col-sm-12 col-lg-6">
        <label
          name="telefoonnummer"
          className="form__label">
          Telefoonnummer*
        </label>
        <input
          type="phone"
          name="telefoonnummer"
          className="form__input"
          ref={register({
            required: 'Telefoonnummer is vereist'
          })}
          disabled={isSubmitting}
        />
        {errors.telefoonnummer && <div className="form__error">{errors.telefoonnummer.message}</div>}
      </div>

      <div className="col-sm-12 col-lg-6">
        <label
          name="onderwerp"
          className="form__label">
          Onderwerp / Cursustype*
        </label>
        <input
          type="text"
          name="onderwerp"
          className="form__input"
          ref={register({
            required: 'Onderwerp is vereist'
          })}
          disabled={isSubmitting}
        />
        {errors.onderwerp && <div className="form__error">{errors.onderwerp.message}</div>}
      </div>

      <div className="col-lg-12">
        <label
          name="beschrijving"
          className="form__label">
          Beschrijving
        </label>
        <textarea
          name="beschrijving"
          className="form__input form__input--area"
          disabled={isSubmitting}
        />
      </div>

      <div className="col-sm-10 col-lg-6">
        <div className="form__privacy-statement">
          <input
            type="checkbox"
            name="privacyStatement"
            className="form__input"
            ref={register({
              required: 'Om het formulier te verzenden is het akkoord gaan met de privacy statement verplicht'
            })}
            disabled={isSubmitting}
          />
          <label
            name="privacyStatement"
            className="form__label">
            Om het formulier te verzenden is het akkoord gaan met de <Link to={'/privacy-verklaring'}>privacy verklaring</Link> verplicht
          </label>
        </div>
        {errors.privacyStatement && <div className="form__error">{errors.privacyStatement.message}</div>}
      </div>

      <div className="col-lg-12">
        <button className="button" disabled={isSubmitting}>Verzenden</button>
      </div>

    </form>
  )


  return (
    <>
      {errors && errors.submit && showSubmitError(errors.submit.message)}
      {submitted ? showThankYou : showForm}
    </>
  );
}

export default MyForm;